
import { ElButton, ElCheckbox, ElCheckboxGroup, ElDialog } from 'element-plus';
import { defineComponent, PropType, ref, watchEffect } from 'vue';
import { SyncListList } from '../types/audiences';

export default defineComponent({
  name: 'AudiencesDialog',
  props: {
    controlShowDialog: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    loading: {
      type: Boolean as PropType<boolean>,
    },
    list: {
      type: Array as PropType<SyncListList[]>,
      required: true,
    },
  },
  emits: ['cancel', 'confirm', 'close'],
  setup(props, { emit }) {
    const isShowDialog = ref(false);
    watchEffect(() => {
      isShowDialog.value = props.controlShowDialog;
    });

    const cancel = () => {
      emit('cancel');
    };
    const confirm = () => {
      emit('confirm', checkedList.value.join(','));
    };
    const close = () => {
      emit('close');
    };

    // 复选框相关
    const isIndeterminate = ref(false); // 是否只选择了一部分
    const checkAll = ref(false); // 是否全选
    const checkedList = ref<string[]>([]);

    const checkedChange = () => {
      // 设置全选状态
      checkAll.value = checkedList.value.length === props.list.length;
      // 如果没有全选(checkAll.value === false)并且被选中的选项数不为0(checkedList.value.length !== 0)，那么就是选择了一部分
      isIndeterminate.value = !checkAll.value && !!checkedList.value.length;
    };

    // 全选
    const handleCheckAllChange = val => {
      if (val) {
        // 全选时，把 props.list 中的所有id给 checkedList
        checkedList.value = props.list.map(item => item.criteo_advertiser_id);
      } else {
        // 取消全选
        checkedList.value = [];
      }
      isIndeterminate.value = false;
    };
    return {
      isShowDialog,
      cancel,
      confirm,
      close,
      isIndeterminate,
      checkAll,
      handleCheckAllChange,
      checkedList,
      checkedChange,
    };
  },
  components: {
    ElDialog,
    ElCheckbox,
    ElCheckboxGroup,
    ElButton,
  },
});
