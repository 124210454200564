import request from '@/tools/request';
import {
  AudiencesRes,
  ClearAudiences,
  ClearAudiencesRes,
  GetAudiences,
  GetSyncList,
  SyncAudiences,
  SyncListRes,
} from '../types/audiences';
import { Res } from '../types/common';

// 列表
export const getAudiences: GetAudiences = (params, loading) => {
  return request({
    url: '/criteo-audience-list.html',
    params,
    loading,
  }) as Promise<AudiencesRes>;
};

// 同步
export const syncAudiences: SyncAudiences = (params, loading) => {
  return request({
    url: '/criteo-audience-sync.html',
    params,
    loading,
  }) as Promise<Res<{ result: boolean }>>;
};

// 同步列表
export const getSyncList: GetSyncList = loading => {
  return request({
    url: '/criteo-advertiser-effectivelist.html',
    loading,
  }) as Promise<SyncListRes>;
};

// 清空受众人
export const clearAudiences: ClearAudiences = (params, loading) => {
  return request({
    url: '/criteo-audience-clear.html',
    loading,
    params,
  }) as Promise<ClearAudiencesRes>;
};
