
import { computed, defineComponent, onMounted, ref } from 'vue';
import MTable from '@/components/table/Table.vue';
import { getRoute } from '@/tools/config';
import { clearAudiences, getAudiences, getSyncList, syncAudiences } from '../api/audiences';
import { AudiencesList, SyncListList } from '../types/audiences';
import AudiencesDialog from '../components/AudiencesDialog.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import { ElMessage, ElTableColumn, ElButton, ElTag, ElInput } from 'element-plus';

export default defineComponent({
  name: 'LexionDefault',
  setup() {
    const tableList = ref<AudiencesList[]>([]);
    const listLoading = ref(false);

    const loading = computed(() => listLoading.value || syncListLoading.value);

    const pagination = ref({
      total: 0,
      pageSize: 10,
      currentPages: 1,
    });
    const keyword = ref('');

    // 初始化
    const initTable = async (page?: number) => {
      const res = await getAudiences(
        {
          page: page || 1,
          page_size: pagination.value.pageSize,
          keyword: keyword.value,
        },
        listLoading,
      );

      if (!res.status) return;

      const { data } = res;

      tableList.value = data?.list || [];

      pagination.value.total = data?.pages?.total || 0;
      pagination.value.pageSize = data?.pages?.page_size || 10;
    };

    const syncListLoading = ref(false);
    const syncList = ref<SyncListList[]>([]);
    const initSyncList = async () => {
      const { data, status } = await getSyncList(syncListLoading);

      if (!status) return;

      syncList.value = data?.list || [];
    };

    // 换页
    const pageChange = cur => {
      pagination.value.currentPages = cur;
      initTable(cur);
    };

    // 搜索
    const search = () => {
      initTable();
    };

    // 点击同步显示弹窗
    const isShowDialog = ref(false);
    const syncRow = ref<AudiencesList>({} as AudiencesList);
    const showDialog = async (element: AudiencesList) => {
      reloadDialog.value++; // 清空弹框的数据
      if (!syncList.value.length) {
        ElMessage({
          type: 'warning',
          message: '请前往商户管理绑定账号',
        });
        return;
      } else if (parseInt(element.user_count) === 0) {
        ElMessage({
          type: 'warning',
          message: '关联用户数量为0，不能同步',
        });
        return;
      }

      isShowDialog.value = true;
      syncRow.value = element;
    };

    const reloadDialog = ref(0);
    const syncData = async selsId => {
      if (!selsId) {
        ElMessage({
          type: 'warning',
          message: '请选择同步账户',
        });
        return;
      }
      closeDialog();
      syncRow.value.loading = true; // loading状态
      const { status } = await syncAudiences(
        { tagId: syncRow.value?.id || '', advertiserIds: selsId },
        ref(false),
      );

      syncRow.value.loading = false;

      if (!status) return;

      initTable(pagination.value.currentPages);

      ElMessage({
        type: 'success',
        message: '操作成功',
      });
    };

    // 关闭弹出
    const closeDialog = () => {
      isShowDialog.value = false;
    };

    // 清空受众
    const clear = async (row: AudiencesList) => {
      row.delLoading = true;
      const { status } = await clearAudiences({ tagId: row.id }, ref(false));
      if (status) {
        initTable(pagination.value.currentPages);
        ElMessage({
          type: 'success',
          message: '用户已清空',
        });
      }
    };

    onMounted(() => {
      initTable();
      initSyncList();
    });

    return {
      tableList,
      loading,
      getRoute,
      pagination,
      showDialog,
      pageChange,
      keyword,
      search,
      isShowDialog,
      closeDialog,
      syncList,
      syncData,
      reloadDialog,
      clear,
    };
  },
  components: { MTable, AudiencesDialog, PageHeader, ElTableColumn, ElButton, ElTag, ElInput },
});
